import React from "react";

import * as Icons from "./EventIcons";

type BaseEvent = {
  displayText: string;
  value: string;
  //extend metadata object to contain calendar types
  metadata: {
    colors?: string[];
    associatedReligions?: Array<string>;
    icon?: JSX.Element;
  };
};
type CalendarEvent = BaseEvent & {
  startDate: string;
  endDate: string;
  TypeOfEvent: "Calendar";
};

type PersistentEvent = BaseEvent & { TypeOfEvent: "Persistent" };

export type EventType = CalendarEvent | PersistentEvent;

export const eventsArray: Array<EventType> = [
  {
    displayText: "Valentine's Day",
    startDate: "02-14-2025",
    endDate: "02-14-2025",
    value: "Valentines Day",
    TypeOfEvent: "Calendar",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.ValentinesChocolateHeart />,
      colors: ["#f01a1a"],
    },
  },
  {
    displayText: "Lunar New Year",
    startDate: "01-29-2025",
    endDate: "01-29-2025",
    value: "Lunar New Year",
    TypeOfEvent: "Calendar",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.LunarNewYearLamp />,
    },
  },
  {
    displayText: "Earth Day",
    startDate: "04-22-2025",
    endDate: "04-22-2025",
    value: "Earth Day",
    TypeOfEvent: "Calendar",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.EarthDay />,
    },
  },
  {
    displayText: "Mother's Day",
    startDate: "05-11-2025",
    endDate: "05-11-2025",
    value: "Mothers Day",
    TypeOfEvent: "Calendar",
    metadata: { colors: ["#FE3A3A"], associatedReligions: ["NA", "none"] },
  },
  {
    displayText: "Father's Day",
    startDate: "06-15-2025",
    endDate: "06-15-2025",
    value: "Fathers Day",
    TypeOfEvent: "Calendar",
    metadata: { colors: ["#EC892B"], associatedReligions: ["NA", "none"] },
  },
  {
    displayText: "Rosh Hashana",
    startDate: "09-22-2025",
    endDate: "09-24-2025",
    value: "Rosh Hashana",
    TypeOfEvent: "Calendar",
    metadata: { associatedReligions: ["jewish"] },
  },
  {
    displayText: "Halloween",
    startDate: "10-31-2025",
    endDate: "10-31-2025",
    value: "Halloween",
    TypeOfEvent: "Calendar",
    metadata: {
      colors: ["#e68f39"],
      associatedReligions: ["NA", "none"],
      icon: <Icons.JackoLantern />,
    },
  },

  {
    displayText: "Thanksgiving",
    startDate: "11-27-2025",
    endDate: "11-27-2025",
    value: "Thanksgiving",
    TypeOfEvent: "Calendar",
    metadata: {
      colors: ["#c26102"],
      associatedReligions: ["NA", "none"],
      icon: <Icons.Turkey />,
    },
  },
  {
    displayText: "Chanukah",
    startDate: "12-25-2025",
    endDate: "01-02-2026",
    value: "Chanukah",
    TypeOfEvent: "Calendar",
    metadata: {
      associatedReligions: ["jewish"],
      icon: <Icons.Menorah />,
    },
  },
  {
    displayText: "Christmas",
    startDate: "12-25-2025",
    endDate: "12-31-2025",
    value: "Christmas",
    TypeOfEvent: "Calendar",
    metadata: {
      colors: ["#FF0000", "white", "green"],
      associatedReligions: ["christian", "none"],
      icon: <Icons.ChristmasTree />,
    },
  },

  {
    displayText: "New Year's",
    startDate: "01-01-2026",
    endDate: "01-02-2026",
    value: "New Years",
    TypeOfEvent: "Calendar",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.ChanpagneGlasses />,
    },
  },
  {
    displayText: "Birthday",
    value: "Birthday",
    TypeOfEvent: "Persistent",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.BirthdayCupcake />,
      colors: ["#4287f5"],
    },
  },
  {
    displayText: "Wedding",
    value: "Wedding",
    TypeOfEvent: "Persistent",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.WeddingCake />,
    },
  },
  {
    displayText: "Graduation",
    value: "Graduation",
    metadata: {
      colors: ["#0f9ad6"],
      associatedReligions: ["NA", "none"],
      icon: <Icons.GraduationCap />,
    },
    TypeOfEvent: "Persistent",
  },
  {
    displayText: "Get Well Soon",
    value: "Get Well Soon",
    metadata: {
      associatedReligions: ["NA", "none"],
      icon: <Icons.TeaLeaves />,
    },
    TypeOfEvent: "Persistent",
  },
  {
    displayText: "Teacher's Appreciation",
    value: "Teachers appreciation",
    metadata: {
      colors: ["#5e1db8"],
      associatedReligions: ["NA", "none"],
      icon: <Icons.Books />,
    },
    TypeOfEvent: "Persistent",
  },
];
